<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <form @submit.prevent="password(model)">
                  <h1>Восстановление пароля</h1>
                  <p class="text-muted">Введите e-mail, использующийся для входа в аккаунт</p>
                  <CInput
                      type="email"
                      v-model.lazy="model.email"
                      required
                      placeholder="E-mail"
                      autocomplete="email"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-user"/>
                    </template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton type="submit" color="primary" class="px-4">Получить новый пароль</CButton>
                    </CCol>
                  </CRow>
                  <notifications group="pass" />
                </form>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>

import axios from '@/modules/axios'

export default {
  name: 'Password',
  data() {
    return {
      model: {
        email: ''
      }
    }
  },

  methods: {
    password({email}) {
      const data = {
        email: email
      }
      axios.post('/auth/password', data, {
        headers: {'content-type': 'application/json'}
      }).then((res) => {
        this.$notify({ group: 'pass', text: res.data.data.message })
        setTimeout(() => {this.$router.push('/login')}, 2000);
      }).catch((error) => {
        this.$notify({group: 'pass', text: error.response.data.message, type: 'error'})
      })
    }
  }
}
</script>
